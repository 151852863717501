export interface Props {
  children: React.ReactNode
}

export default function BackgroundWrapper({ children }: Props) {
  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <img
        src="/assets/images/sand.jpg"
        alt="img"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 0.2
        }}
      />
      <div
        style={{
          position: 'relative'
        }}
      >
        {children}
      </div>
    </div>
  )
}
