import BenefitsOfHavingSolarPanels from 'widgets/BenefitsOfHavingSolarPanels'
import HowDoSolarPanelsWork from 'widgets/HowDoSolarPanelsWork'
import TitleWidget from 'widgets/TitleWidget'

export default function Home() {
  return (
    <div>
      <TitleWidget />
      <hr />

      <HowDoSolarPanelsWork />
      <hr />

      <BenefitsOfHavingSolarPanels />
    </div>
  )
}
