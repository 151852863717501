import { Button, Spacer } from 'fastdo-react'
import PVModel from '../three/PVModel'

export default function TitleWidget() {
  return (
    <div className="home-page-tile">
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            height: '300px',
            width: '40%'
          }}
        >
          <PVModel />
        </div>
        <h1
          style={{
            width: '60%',
            fontSize: '3.8rem',
            paddingRight: '100px'
          }}
        >
          <strong style={{ color: 'var(--fd-primary-color)', fontWeight: '800' }}>Smart Green</strong>: Your
          Energy-Efficient Solution Provider for Homes and Businesses
        </h1>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Spacer size={40} />
        <p
          style={{
            fontSize: '1.6rem'
          }}
        >
          Analyze &nbsp; → &nbsp; Construct &nbsp; → &nbsp; Maintain
        </p>
        <Spacer size={60} />
        <div
          style={{
            display: 'flex'
          }}
        >
          <Button label="Calculate solution" />
          <Spacer size={20} horizontal />
          <Button label="Contact us" outlined styles={{ button: { backgroundColor: 'transparent' } }} />
        </div>
      </div>
    </div>
  )
}
