import { Card, Spacer } from 'fastdo-react'
import { BsBatteryCharging } from 'react-icons/bs'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { BiSolidLeaf } from 'react-icons/bi'

export default function BenefitsOfHavingSolarPanels() {
  return (
    <div className="home-page-tile">
      <div
        style={{
          width: '1000px',
          margin: 'auto'
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Benefits of having solar panels</h2>
        <Spacer size={20} />

        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BsBatteryCharging style={{ width: '40px', height: '40px' }} />
                <Spacer horizontal />
                <h3>Energy efficiency</h3>
              </div>
              <Spacer />
              <p>Solar panels are a great way to reduce energy consumption and make your home more energy efficient.</p>
            </Card>
          </div>

          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaMoneyBillAlt style={{ width: '40px', height: '40px' }} />
                <Spacer horizontal />
                <h3>Money savings</h3>
              </div>
              <Spacer />
              <p>
                The reduced energy consumption will make your electricity bill lower, which will save you a lot of
                money.
              </p>
            </Card>
          </div>
        </div>
        <Spacer size={40} />

        <div style={{ margin: 'auto', maxWidth: '350px' }}>
          <Card>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BiSolidLeaf style={{ width: '40px', height: '40px' }} />
              <Spacer horizontal />
              <h3>Sustainable living</h3>
            </div>
            <Spacer />
            <p>Your footprint on the environment will be reduced, which will make your living more sustainable.</p>
          </Card>
        </div>
      </div>
    </div>
  )
}
