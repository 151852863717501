import * as THREE from 'three'
import { useEffect } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'

export default function PVModel() {
  useEffect(() => {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.set(0, 0, 10)
    camera.lookAt(0, 0, 0)

    const renderer = new THREE.WebGLRenderer()
    var threeContainer = document.querySelector('#three-container')

    if (!threeContainer) return

    renderer.setSize(threeContainer.clientWidth, threeContainer.clientHeight)
    renderer.setClearColor(0x000000, 0)

    threeContainer?.appendChild(renderer.domElement)

    const loader = new GLTFLoader()

    var pvPanel: any
    loader.load('/assets/models/pv-panel.glb', (gltf: any) => {
      const scaleFactor = 0.03
      gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor)
      gltf.scene.position.set(0, 0, 0)

      pvPanel = gltf

      scene.add(gltf.scene)
    })

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
    scene.add(ambientLight)

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.update()

    function animate() {
      requestAnimationFrame(animate)

      if (pvPanel && pvPanel.scene) {
        pvPanel.scene.rotation.y += 0.01
      }

      controls.update()

      renderer.render(scene, camera)
    }

    animate()
  }, [])

  return <div id="three-container" style={{ width: '100%', height: '100%' }}></div>
}
