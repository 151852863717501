import { Card, Spacer } from 'fastdo-react'

export default function HowDoSolarPanelsWork() {
  return (
    <div className="home-page-tile">
      <div
        style={{
          width: '1000px',
          margin: 'auto'
        }}
      >
        <h2 style={{ textAlign: 'center' }}>How do solar panels work?</h2>

        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <p style={{ fontSize: '20px' }}>
                <strong>1</strong>
              </p>
              <Spacer />
              <p>
                Light particles from the sun called photons hit the PV panel, which will make free electrons from the
                silicon atoms.
              </p>
            </Card>
          </div>

          <img src="/assets/images/pv-panel.svg" alt="PV panel" width={160} />

          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <p style={{ fontSize: '20px' }}>
                <strong>4</strong>
              </p>
              <Spacer />
              <p>The AC electricity will be used in the home, which will make your home energy efficient.</p>
            </Card>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <p style={{ fontSize: '20px' }}>
                <strong>2</strong>
              </p>
              <Spacer />
              <p>The free electrons can flow through the conductor, producing direct current (DC) electricity.</p>
            </Card>
          </div>

          <div style={{ margin: 'auto', maxWidth: '350px' }}>
            <Card>
              <p style={{ fontSize: '20px' }}>
                <strong>3</strong>
              </p>
              <Spacer />
              <p>The inverter will transfer the DC electricity to alternating current (AC) electricity.</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
