import BackgroundWrapper from './components/BackgroundWrapper'
import Navbar from 'components/Navbar'
import { Link, Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from 'pages/Home'
import { NotFoundView } from 'fastdo-react'

export default function App() {
  const router = createBrowserRouter([
    {
      path: '',
      element: <InnerApp />,
      children: [{ path: '', element: <Home /> }]
    },
    {
      path: '/404',
      element: (
        <NotFoundView
          actionComponent={
            <Link to="/" className="fd-btn fd-btn-primary">
              Home
            </Link>
          }
        />
      )
    },
    { path: '*', element: <Navigate to="/404" /> }
  ])

  return (
    <BackgroundWrapper>
      <RouterProvider router={router} />
    </BackgroundWrapper>
  )
}

export function InnerApp() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}
