import { Link } from 'react-router-dom'
import { Button, Navbar as FdNavbar } from 'fastdo-react'

export default function Navbar() {
  const smallScreen = 1000

  return (
    <FdNavbar
      LinkElement={Link}
      navItems={[
        { linkProps: { to: '/services' }, label: 'Services' },
        { linkProps: { to: '/contact' }, label: 'Contact' }
      ]}
      navbarBaseProps={{
        headerChildren: (
          <a href="/">
            <h2 style={{ color: 'var(--fd-primary-color)' }}>Smart Green</h2>
          </a>
        ),
        actionChrildren: <Button label="Online calculation" styles={{ button: { padding: '8px 12px' } }} />,
        marginTopOpen: 100,
        smallScreen: smallScreen,
        styles: {
          smallScreenAction: { paddingTop: '20px' }
        }
      }}
    />
  )
}
